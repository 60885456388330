import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import GraphQLErrorList from '../components/graphql-error-list';

import { usePageTitle } from '../hooks/usePageTitle';
import { Block, Col, Layout } from '@components/Blocks';
import Card from '@components/Card';
import BlockWidth from '@components/BlockWidth';
import HeaderPadding from '@layout/HeaderPadding';
import usePaginatedList from '@hooks/usePaginatedList';
import Pagination from '@components/Pagination';
import { ChangeToRandomTheme } from '@layout/Layout';
import WithErrors from '@components/WithErrors';
import styled from 'styled-components';

export const query = graphql`
  query NewsletterPageQuery {
    news: sanityNewsletterSettings {
      posts {
        _id
        title
        image {
          asset {
            url
          }
        }
        slug {
          current
        }
      }
    }
  }
`;

// const getImageFromBody = (body) => {
//   const figure = body.find(({ _type: type }) => type === 'figure');
//   if (!figure) return;

//   return { asset: { url: figure.asset._ref } };
// };

const Wrapper = styled.main`
  padding: 3em 0;
`;

const NewsletterPage = ({ data }) => {
  usePageTitle('Nyhetsbrev');
  const listData = useMemo(
    () =>
      data?.news?.posts.map(({ _id, title, slug: { current: slug }, image, body }) => ({
        _id: _id,
        title,
        image,
        url: `/newsletter/${slug}`,
      })),
    []
  );

  const [posts, pagination] = usePaginatedList(listData, { itemsPerPage: 12 });

  return (
    <Wrapper>
      <HeaderPadding />
      {posts?.length && (
        <BlockWidth align="right">
          <Block>
            <Col type={'3-x'}>
              {posts.map((props) => (
                <Card key={props._id} {...props} />
              ))}
            </Col>
          </Block>
          <Block>
            <Layout pos="center">
              <Pagination onNavigate={() => ChangeToRandomTheme()} {...pagination} />
            </Layout>
          </Block>
        </BlockWidth>
      )}
    </Wrapper>
  );
};

export default WithErrors(NewsletterPage);
